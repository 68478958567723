import React, { useCallback, useEffect, useRef, useState } from 'react';
import classes from './Documents.module.scss'
import get from '../../../services/axios/get';
import DragAndDropUpload from '../../UI/DragAndDrop/DragAndDropUpload';
import { customAlert } from '../../../store/actions/alert';
import { useAppDispatch } from '../../../store/hooks';
import post from '../../../services/axios/post';
import Loader from '../../UI/Loader/Loader';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { CgMenu } from 'react-icons/cg';
import Alert from '../../UI/Alert/Alert';
import del from '../../../services/axios/delete';
import { FaUpload, FaDownload, FaEye, FaWhatsapp } from 'react-icons/fa';
import Load from '../../../models/load';
import LoadPdf, { MyDocument } from '../LoadPdf/LoadPdf';
import { PDFDownloadLink, pdf } from '@react-pdf/renderer';
import ChargesPdf, { ChargesDocument } from '../ChargesPdf/ChargesPdf';
import Modal from '../../../containers/Modal/Modal';
import axios from 'axios';

interface DocumentsProps {
  load: Load
  userType: string
}
export interface Document {
  id: string;
  loadId: string;
  createdAt: string;
  updatedAt: string;
  documentName: string;
  documentType: string;
  key: string;
  signedUrl: string;
  greyedOut?: boolean;
}


const Documents: React.FC<DocumentsProps> = (props: DocumentsProps) => {
  const isMounted = useRef(true);
  const [deletionId, setDeletionId]: [string | null, any] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isSendLoading, setIsSendLoading] = useState(false);
  const [isSendingCTO, setIsSendingCTO] = useState(false);
  const [documents, setDocuments]: [
    Document[],
    (documents: any) => any
  ] = useState([]);
  const dispatch = useAppDispatch();
  const [openDocumentMenu, setOpenDocumentMenu]: [string | null, any] = useState(null);
  const fixedDocuments = [
    'Q67.clientRef.pdf',
    'Container Photo.clientRef.jpeg',
    'Seal Photo.clientRef.jpeg',
    'Temptale Photo.clientRef.jpeg',
    'VGM 1 Photo.clientRef.jpeg',
    'VGM 2 Photo.clientRef.jpeg',
    'Port Slip Photo.clientRef.jpeg'
  ];
  const fileInputRef: any = useRef(null);
  const [documentToUpload, setDocumentToUpload]: [Document | null, any] = useState(null)
  const [showPdf, setShowPdf]: [string | null, any] = useState(null)

  const deleteDocument = async (documentId: string) => {
    del(
      `/document/${documentId}`,
      {},
      () => {
        getDocuments();
      },
      (error: any) => {
        dispatch(customAlert(true, error))
      }
    )
  }

  const onFileUpload = async (file: File, documentName?: string) => {
    setIsLoading(true);

    get(
      '/document/upload',
      {
        documentName: documentName ?? file.name,
        loadId: props.load.id
      },
      async (response) => {
        const uploadResponse = await fetch(response.data.uploadUrl, {
          method: 'PUT',
          body: file,
          headers: {
            'Content-Type': file.type
          }
        });

        if (uploadResponse.ok) {
          post(
            '/document',
            {
              documentName: documentName ?? file.name,
              loadId: props.load.id
            },
            (response) => {
              if (fileInputRef.current != null) {
                fileInputRef.current.value = '';
              }

              getDocuments();
            },
            (error: any) => {
              dispatch(customAlert(true, error))
            }
          )
        } else {
          dispatch(customAlert(true, 'Upload failed'))
        }
      },
      () => { }
    )
  };

  const getDocuments = useCallback(
    () => {
      setIsLoading(true)
      get(
        '/document',
        { loadId: props.load.id },
        (res) => {
          if (isMounted) {
            setDocuments(res.data);
            setIsLoading(false)
          }
        },
        () => { }
      );
    },
    [setDocuments, setIsLoading, props.load.id]
  );

  const renderDocuments = () => {
    const filteredFixedDocuments: Document[] = fixedDocuments.filter(fixedDocName =>
      !documents.some(doc => {
        const actualNameParts = doc.documentName.split('.');
        const fixedNameParts = fixedDocName.split('.');

        return actualNameParts[0] === fixedNameParts[0];
      })
    ).map(name => ({
      documentName: name.split('.')[0],
      greyedOut: true,
      id: '',
      loadId: '',
      signedUrl: '',
      documentType: '',
      createdAt: '',
      updatedAt: '',
      key: name
    }));
    const allDocuments = [...filteredFixedDocuments, ...documents];

    return allDocuments.map((document, index) => {
      const documentClasses = [classes.doc, 'py-1', document.greyedOut ? classes['greyed-out'] : ''].join(' ');

      if (document.documentName.split('.')[0] === 'Booking Photo') {
        return null
      }

      return (
        <DragAndDropUpload onFileUpload={(file) => {
          onClickedFileUpload(file, document.key)
        }}>
          <div className={documentClasses} key={index}>
            <div style={{ paddingRight: '1rem' }}>{document.documentName.split('.')[0]}</div>

            {!document.greyedOut ? (
              <Dropdown
                isOpen={openDocumentMenu === document.id}
                toggle={() => setOpenDocumentMenu(openDocumentMenu === null ? document.id : null)}
                direction={'down'}
                className={classes['dropdown']}
              >
                <DropdownToggle><CgMenu size={'16'} /></DropdownToggle>
                <DropdownMenu>
                  {document.signedUrl && <a className={classes.download} href={document.signedUrl} download={document.documentName}><DropdownItem>

                    Download

                  </DropdownItem></a>}
                  <DropdownItem onClick={() => setDeletionId(document.id)}>Delete</DropdownItem>
                </DropdownMenu>
              </Dropdown>
            ) : (
              props.userType !== 'client' ? (
                <div className={classes.upload} onClick={() => {
                  if (fileInputRef.current?.click != null) {
                    setDocumentToUpload(document);
                  fileInputRef.current.click()
                }
              }}>
                  <FaUpload />
                </div>
              ) : null
            )}
          </div>
        </DragAndDropUpload>
      );
    });
  };

  const onClickedFileUpload = (file: File, documentName?: string): void => {
    // @ts-expect-error
    const nameParts = documentName != null ? documentName.split('.') : documentToUpload.key.split('.');
    const secondPart = props.load[nameParts[1] as keyof Load] != null && props.load[nameParts[1] as keyof Load] !== '' ? `.${props.load[nameParts[1] as keyof Load]}` : '';
    const fileNameParts = file.name.split('.')

    onFileUpload(file, `${nameParts[0]}${secondPart}.${fileNameParts[fileNameParts.length - 1]}`);
  }

  const sendCTOToDriver = async () => {
    setIsSendLoading(true)

    const doc = <MyDocument load={props.load} />;
    const blob = await pdf(doc).toBlob();

    get(
      '/send',
      { documentName: `CTO.${props.load.clientRef}.pdf` },
      async (res) => {
        if (isMounted) {
          setIsSendLoading(false)
        }

        const uploadUrl = res.data.uploadUrl
        const key = res.data.key

        try {
          await axios.put(uploadUrl, blob, {
            headers: {
              'Content-Type': 'application/pdf',
            },
          });

          post(
            '/send',
            {
              key: key,
              loadId: props.load.id
            },
            (response) => {
              dispatch(customAlert(true, 'CTO sent successfully'))
              setIsSendLoading(false);
            },
            (error: any) => {
              dispatch(customAlert(true, error))
              setIsSendLoading(false);
            }
          )
        } catch (error) {
          dispatch(customAlert(true, 'CTO Upload failed'))
          setIsSendLoading(false);
        }
      },
      () => {
        dispatch(customAlert(true, 'Failed to get upload url'))
        setIsSendLoading(false);
      }
    );
  };

  useEffect(() => {
    getDocuments();

    return () => {
      isMounted.current = false;
    }
  }, [
    getDocuments
  ])

  return (
    <div className={classes['documents']}>
      <input
        type="file"
        style={{ display: 'none' }}
        ref={fileInputRef}
        onChange={(event) => {
          const file = event?.target?.files?.[0];

          if (!file) {
            return;
          }


          onClickedFileUpload(file);
        }}
      />


      <Alert
        confirm={(confirmation) => {
          if (confirmation && deletionId != null) {
            deleteDocument(deletionId);
            setDeletionId(null);
          } else {
            setDeletionId(null);
          }
        }}
        message={
          <div>
            Are you sure you want to delete this document?
          </div>
        }
        isOpen={deletionId !== null}
        isConfirm
      />

      <Alert
        confirm={(confirmation) => {
          if (confirmation) {
            sendCTOToDriver()
          }

          setIsSendingCTO(false)
        }}
        message={
          <div>
            Are you sure you want to send this CTO to the driver?
          </div>
        }
        isOpen={isSendingCTO}
        isConfirm
      />

      <h6>Documents</h6>

      {isLoading ? (
        <div className='p-3'>
          <Loader size='medium' />
        </div>
      ) : (
        <div>
          {
            showPdf === 'cto' ? (
              <Modal close={() => setShowPdf(null)} style={{ width: '1000px' }} stacked={true} stretch>
                <LoadPdf load={props.load} close={() => setShowPdf(null)}></LoadPdf>
              </Modal>
            ) : null
          }

          {
            showPdf === 'charges' ? (
              <Modal close={() => setShowPdf(null)} style={{ width: '1000px' }} stacked={true} stretch>
                <ChargesPdf load={props.load} close={() => setShowPdf(null)}></ChargesPdf>
              </Modal>
            ) : null
          }

          <div className='p-3'>
            <div className={[classes.doc, 'py-1'].join(' ')} key={'01'}>
              <div style={{ paddingRight: '1rem' }}>CTO</div>

              <div className={[classes.dropdown, classes.upload].join(' ')}>
                {isSendLoading ? (
                  <div className='px-2' style={{ display: 'inline-block' }}>
                    <Loader size='tiny' />
                  </div>
                ) : (
                  props.userType !== 'client' ? (
                    <FaWhatsapp style={{ color: 'var(--theme-blue)', marginRight: '0.5rem' }} onClick={() => setIsSendingCTO(true)} />
                  ) : null
                )}

                <FaEye style={{ color: 'var(--theme-blue)', marginRight: '0.5rem' }} onClick={() => setShowPdf('cto')} />
                <PDFDownloadLink document={<MyDocument load={props.load} />} fileName={`CTO.${props.load.clientRef}.pdf`}>
                  {({ blob, url, loading, error }) => (loading ? '' : <FaDownload style={{ color: 'var(--theme-blue)' }} />)}
                </PDFDownloadLink>
              </div>
            </div>

            <div className={[classes.doc, 'py-1'].join(' ')} key={'02'}>
              <div style={{ paddingRight: '1rem' }}>Additional Charges</div>

              <div className={[classes.dropdown, classes.upload].join(' ')}>
                <FaEye style={{ color: 'var(--theme-blue)', marginRight: '0.5rem' }} onClick={() => setShowPdf('charges')} />
                <PDFDownloadLink document={<ChargesDocument load={props.load} />} fileName={`Charges.${props.load.clientRef}.pdf`}>
                  {({ blob, url, loading, error }) => (loading ? '' : <FaDownload style={{ color: 'var(--theme-blue)' }} />)}
                </PDFDownloadLink>
              </div>
            </div>

            {renderDocuments()}
          </div>

          {props.userType !== 'client' && (
            <DragAndDropUpload boxed onFileUpload={onFileUpload} />
          )}
        </div>
      )}
    </div >
  );
};

export default Documents;
