import React, { useState } from 'react';
import classes from './EntityBox.module.scss';
import Loader from '../../../UI/Loader/Loader';
import { FaEdit, FaPlus, FaTrash } from 'react-icons/fa';
import Alert from '../../../UI/Alert/Alert';
import Input from '../../../UI/Input/Input';
import checkValidity from '../../../../helpers/checkValidity';
import { customAlert } from '../../../../store/actions/alert';
import { useAppDispatch } from '../../../../store/hooks';
import NewEntity, { Entity } from './NewEntity/NewEnity';

interface EntityBoxProps {
  header: string;
  isLoading: boolean;
  isBroken: boolean;
  entities: Array<any>;
  delete: (entityId: string) => void;
  edit?: (entityId: string, entityName: string, phoneNumber: string) => void;
  createNewEntity: (newEntity: Entity) => void;
  entityName: string;
};

const EntityBox: React.FC<EntityBoxProps> = (props) => {
  const dispatch = useAppDispatch();
  const [searchTerm, setSearchTerm] = useState('');
  const [deletionId, setDeletionId]: [string | null, any] = useState(null)
  const [deletionName, setDeletionName]: [string | null, any] = useState(null);
  const [editId, setEditId]: [string | null, any] = useState(null)
  const [editName, setEditName]: [string, any] = useState('');
  const [editPhoneNumber, setEditPhoneNumber]: [string, any] = useState('');
  const [showNew, setShowNew] = useState(false);
  const [newEntity, setNewEntity] = useState({ alias: '' });

  const filteredEntities = props.entities.filter(entity =>
    entity.alias.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const renderEntities = () => {
    const entities = filteredEntities.map((entity, index) => {
      const shadeClass = index % 2 === 0 ? 'even' : 'odd';

      return <div key={entity.id} className={[classes.entity, classes[shadeClass]].join(' ')}>
        {editId === entity.id ? (
          <>
            <Input
              elementType={'input'}
              elementConfig={{
                type: 'text',
                placeholder: entity.alias
              }}
              value={editName}
              change={(e: any) => setEditName(e.target.value)}
              id={entity.id}
              labelHidden
              inputStyle='edit'
              shouldValidate={true}
              touched={true}
              invalid={!checkValidity(
                editName, {
                required: true,
                minLength: 3,
                maxLength: 40
              }
              )}
            />

            {props.entityName === 'driver' ? (
              <Input
                elementType={'input'}
                elementConfig={{
                  type: 'text',
                  placeholder: 'Phone Number'
                }}
                value={editPhoneNumber}
                change={(e: any) => setEditPhoneNumber(e.target.value)}
                id={entity.id}
                labelHidden
                inputStyle='edit'
              />
            ) : null}
          </>
        ) : (
          <>
            <div>{entity.alias}</div>
            <div>{entity.phoneNumber}</div>
          </>
        )}

        <div className='d-flex'>
          {editId !== entity.id ? (<>
            {props.edit ? (
              <div
                onClick={() => {
                  setEditId(entity.id);
                setEditName(entity.alias);
                setEditPhoneNumber(entity.phoneNumber);
              }}
                className={['px-4', 'pointer'].join(' ')}>
                <FaEdit color='var(--theme-blue)' /></div>
            ) : null}
            <div
              className={['pointer'].join(' ')}
              onClick={() => {
                setDeletionId(entity.id);
                setDeletionName(entity.alias)
              }}
            ><FaTrash color='var(--theme-red)' /></div>
          </>) : (
            <div className={classes['submit-edit']}>
              <div
                onClick={() => {
                  setEditId(null);
                  setEditName('');
                  setEditPhoneNumber(entity.phoneNumber);
                }}
                className={[classes['cancel'], 'pointer'].join(' ')}>
                Cancel</div>
              <div
                onClick={() => {
                  if (checkValidity(editName, {
                    required: true,
                    minLength: 3,
                    maxLength: 40
                  })) {
                    setEditId(null);
                    setEditName('');
                    if (props.edit) {
                      props.edit(entity.id, editName, editPhoneNumber);
                    }
                  } else {
                    dispatch(customAlert(true, 'Invalid name'))
                  }
                }
                }
                className={[classes['save'], 'pointer'].join(' ')}>
                Save</div>
            </div>
          )}

        </div>
      </div>
    })

    return entities
  }

  return (
    <div className={classes['entity-box']}>
      {showNew ? (
        <NewEntity
          close={() => {
            setShowNew(false);
            setNewEntity({ alias: '' })
          }}
          newEntity={newEntity}
          setNewEntity={setNewEntity}
          createNewEntity={props.createNewEntity}
          entityName={props.entityName}
        />
      ) : null}


      <Alert
        confirm={(confirmation) => {
          if (confirmation && deletionId !== null) {
            props.delete(deletionId);
            setDeletionId(null);
            setDeletionName(null);
          } else {
            setDeletionId(null);
            setDeletionName(null);
          }
        }}
        message={
          <div>
            Are you sure you want to delete the {props.entityName} named {deletionName}?
          </div>
        }
        isOpen={deletionId !== null}
        isConfirm
      />

      <div className={classes.header}>{props.header}</div>

      <div className={[classes.top, 'px-3', 'py-2'].join(' ')}>
        <div>
          <Input
            elementType={'input'}
            elementConfig={{
              type: 'text',
              placeholder: 'Search...'
            }}
            value={searchTerm}
            change={(e: any) => setSearchTerm(e.target.value)}
            id={'search-box'}
            labelHidden
            inputStyle='edit'
          />
        </div>

        <div onClick={() => setShowNew(true)} className={[classes.create, 'pointer', 'no-select'].join(' ')}>
          <FaPlus color='var(--theme-blue)' /><div>Create New</div>
        </div>
      </div>

      {props.isLoading ? (
        <div className={classes['loader-box']}>
          <Loader size='large' />
        </div>
      ) : (<div className={classes['entity-container']}>
        {renderEntities()}
      </div>)}
    </div>
  );
};

export default EntityBox;